<template>
  <div id="jobs-view">
    <content-section
      v-if="!selectedJob && !smFilterPanel || (smFilterPanel && $vuetify.breakpoint.mdAndUp)"
      :title="$vuetify.breakpoint.mdAndUp ? $localize.get('jobs') : undefined"
      type="view"
      blue-bg
    >
      <template v-slot:title>
        <div class="title-container--sm" @click="smFilterPanel = true">
          <div class="title-container__title">
            {{$localize.get('jobs')}}
          </div>
          <div class="title-container__filter">
            <img src="../assets/filter-blue.svg">
            {{$localize.get('filters')}}
          </div>
        </div>
      </template>
      <template v-slot:content>
        <div class="content">
          <div class="filter">
            <div class="filter__header">
              <img src="../assets/filter.svg">
              {{$localize.get('filters')}}
            </div>
            <div class="filter__items">
              <div
                v-for="(item, index) in filterItems"
                :key="'item-' + index"
              >
                <v-combobox
                  v-model="filterItems[index].selected"
                  class="filter__item padding-16"
                  v-if="item.type === undefined"
                  :items="item.listItems"
                  :label="$localize.get(item.title)"
                  :multiple="item.multiple"
                  outlined
                  hide-details
                  :return-object="item.type === undefined"
                >
                  <template v-slot:append>
                    <img src="../assets/chevron-down-blue.svg">
                  </template>
                  <template v-slot:selection="{ item: selectionItem, index }">
                    <span v-if="index === 0">{{ selectionItem }}</span>
                    <span
                      v-if="index === 1"
                      class="grey--text caption ml-1"
                    >
                      (+{{ item.listItems.length - 1 }} {{$localize.get('other')}})
                    </span>
                  </template>
                </v-combobox>
                <div
                  v-if="item.type === 'separator'"
                >
                  <v-divider class="my-6"></v-divider>
                </div>
              </div>
            </div>
          </div>
          <div class="main">
            <v-data-iterator
              :items="jobsItems"
              :items-per-page.sync="itemsPerPage"
              :page.sync="page"
              hide-default-footer
            >
              <template v-slot:default="props" class="cards">
                <v-row>
                  <v-col
                    v-for="(item, index) in props.items"
                    :key="index"
                    cols="12"
                    sm="12"
                    md="12"
                    lg="6"
                    xl="6"
                  >
                    <div 
                      class="cards__item"
                      @click="openVacancy(item)"
                    >
                      <div>
                        <div class="cards__item__title">
                          {{item[selectedLanguage.locale].title}}
                        </div>
                        <div class="cards__item__position">
                          {{item[selectedLanguage.locale].position}}
                        </div>
                        <div class="cards__item__tags">
                          <div 
                            v-for="(tagItem, index) in item[selectedLanguage.locale].tags"
                            :key="'item-' + index"
                          >
                            <div 
                              class="cards__item__tags__item"
                              v-if="index < 2"
                            >
                              {{ tagItem }}
                            </div>
                          </div>
                          <div class="cards__item__tags__hidden-item-text">
                            {{hiddenTagAmount(item)}}
                          </div>
                        </div>
                        <div class="cards__item__description line-clamp--2">
                          {{item[selectedLanguage.locale].description}}
                        </div>
                      </div>
                      <h-button link blue no-padding>
                        {{$localize.get('read_more')}}
                      </h-button>
                    </div>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:footer>
                <div class="footer">
                  <div class="footer__results">
                    {{$localize.get('show_on_page')}}:
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          class="footer__results__selector"
                          v-bind="attrs"
                          v-on="on"
                          dark
                          text
                        >
                          {{ itemsPerPage }}
                          <img src="../assets/chevron-down-grey.svg">
                        </div>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(number, index) in itemsPerPageArray"
                          :key="index"
                          @click="itemsPerPage = number"
                        >
                          <v-list-item-title>{{ number }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    {{$localize.get('of', {capitalize: false})}} {{jobsItems.length}}
                  </div>
                  <div class="footer__pagination">
                    <pagination
                      :page="page"
                      :items="jobsItems"
                      :itemsPerPage="itemsPerPage"
                      @input="page = $event"
                      scrollIntoViewSelector=".content__section--view__inner__title"
                    >
                    </pagination>
                  </div>
                </div>
              </template>
            </v-data-iterator>
          </div>
        </div>
      </template>
    </content-section>

    <content-section
      v-if="smFilterPanel && $vuetify.breakpoint.smAndDown && !filterDetails.value"
      type="view"
      blue-bg
    >
      <template v-slot:title>
        <div class="title-container--sm" :smFilterPanel="smFilterPanel">
          <div class="title-container__title">
            {{$localize.get('filters')}}
          </div>
          <div class="title-container__filter">
            <v-icon @click="smFilterPanel = false">mdi-close</v-icon>
          </div>
        </div>
      </template>
      <template v-slot:content>
        <div class="filter--sm">
          <div class="filter--sm__header">
            <div>{{$localize.get('selected_filters')}}: {{amountSelectedFilters}}</div>
            <h-button
              text blue uppercase no-padding
              @click.native="resetFilters()"
            >{{$localize.get('reset')}}
            </h-button>
          </div>
          <div class="filter--sm__items">
            <div
              v-for="(item, index) in filterItems"
              :key="'item-' + index"
            >
              <div
                class="filter--sm__item padding-16"
                v-if="item.type === undefined"
                @click="openFilterDetails(item)"
                :items="item.listItems"
                :label="$localize.get(item.title)"
                :multiple="item.multiple"
                hide-details
              >
                <div class="filter--sm__item__content">
                  <div class="filter--sm__item__title">
                    {{$localize.get(item.title)}}
                  </div>
                  <div class="filter--sm__item__selected">
                    {{selectedFiltersFormatted(item)}}
                  </div>
                </div>
                <img src="../assets/chevron-down-blue.svg">
              </div>
            </div>
          </div>
          <h-button 
            class="mt-12"
            @click.native="smFilterPanel = false"
            green filled
            :large="$vuetify.breakpoint.smAndDown"
            :block="$vuetify.breakpoint.smAndDown"
          >{{$localize.get('show')}} 9 {{$localize.get('vacancies_jobs', {capitalize: false})}}
          </h-button>
        </div>
      </template>
    </content-section>

    <drawer>
      <content-section
        v-if="smFilterPanel && $vuetify.breakpoint.smAndDown && filterDetails.value"
        type="view"
        blue-bg
        style="height: 100%"
        class="padding-24"
      >
        <template v-slot:content>
          <div class="filter-details">
            <div class="filter-details__header">
              <div>
                <h-button
                  text no-padding link
                  @click.native="closeFlterDetails()"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                  {{$localize.get('back')}}
                </h-button>
              </div>
            </div>
            <div class="filter-details__search">
              <input 
                v-model="filterSearchQuery"
                type="text" 
                :placeholder="$localize.get('search')"
              >
              <v-icon>mdi-magnify</v-icon>
            </div>
            <div class="filter-details__info">
              <div>{{$localize.get('selected_filters')}}: {{amountSelectedFilters}}</div>
              <h-button
                text blue uppercase no-padding link
                @click.native="resetFilters()"
              >{{$localize.get('reset')}}
              </h-button>
            </div>
            <div class="filter-details__items">
              <div v-if="filterDetails.item.multiple">
                <v-checkbox
                  v-show="isMactchingFilterQuery(item)"
                  v-model="filterDetails.item.selected"
                  v-for="(item, index) in filterDetails.item.listItems"
                  :key="'item-' + index"
                  :value="item"
                  :label="item"
                  hide-details
                ></v-checkbox>
              </div>
              <div v-if="!filterDetails.item.multiple">
                <v-combobox
                  v-model="filterDetails.item.selected"
                  class="filter-details__item padding-16"
                  :items="filterDetails.item.listItems"
                  :label="filterDetails.item.title"
                  outlined
                  hide-details
                  :return-object="filterDetails.item.type === undefined"
                >
                  <template v-slot:append>
                    <img src="../assets/chevron-down-blue.svg">
                  </template>
                  <template v-slot:selection="{ item: selectionItem, index }">
                    <span v-if="index === 0">{{ selectionItem }}</span>
                    <span
                      v-if="index === 1"
                      class="grey--text caption ml-1"
                    >
                      (+{{ filterDetails.item.listItems.length - 1 }} {{$localize.get('other')}})
                    </span>
                  </template>
                </v-combobox>
              </div>
            </div>
            <h-button 
              class="mt-12"
              @click.native="closeFlterDetails()"
              green filled
              :large="$vuetify.breakpoint.smAndDown"
              :block="$vuetify.breakpoint.smAndDown"
            >{{$localize.get('apply')}}
            </h-button>
          </div>
        </template>
      </content-section>
    </drawer>

    <content-section
      v-if="selectedJob && !smFilterPanel || (smFilterPanel && $vuetify.breakpoint.mdAndUp)"
      type="view"
      blue-bg
    >
      <template v-slot:content>
        <div class="subsection__header">
          <div class="subsection__header__path">
            <span 
              class="subsection__header__path__item"
              @click="closeVacancy()"
            >{{$localize.get('jobs')}} 
            </span> 
            <v-icon>mdi-chevron-right</v-icon>
            <span class="subsection__header__path__item--active">
              {{selectedJob[selectedLanguage.locale].title}}
            </span> 
          </div>
          <h-button 
            class="apply-button--top"
            green filled
          >{{$localize.get('apply_for_this_job')}}
          </h-button>
        </div>
        <div class="subsection__content">
          <div class="subsection__content__info">
            <div>
              <div class="job__item">
                <div class="job__item__subtitle">
                  {{$localize.get('project_description')}}
                </div>
                <div 
                  class="job__item__content"
                  v-html="selectedJob[selectedLanguage.locale].projectDescription"
                ></div>
                <div class="job__item__details">
                  <div 
                    class="job__item__details__item--object-list"
                    v-for="(projectDescriptionDetailsItem, index) in selectedJob[selectedLanguage.locale].projectDescriptionDetails"
                    :key="'item-' + index"
                  >
                    <b class="mr-3">{{projectDescriptionDetailsItem.title}}</b> 
                    {{projectDescriptionDetailsItem.value}}
                  </div>
                </div>
              </div>
              <div class="job__item">
                <div class="job__item__subtitle">
                  {{$localize.get('role_description')}}
                </div>
                <div 
                  class="job__item__content"
                  v-html="selectedJob[selectedLanguage.locale].roleDescription"
                ></div>
                <div class="job__item__details">
                  <div 
                    class="job__item__details__item--object-list"
                    v-for="(roleDescriptionDetailsItem, index) in selectedJob[selectedLanguage.locale].roleDescriptionDetails"
                    :key="'item-' + index"
                  >
                    <b class="mr-3">{{roleDescriptionDetailsItem.title}}</b> 
                    {{roleDescriptionDetailsItem.value}}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="job__item">
                <div class="job__item__subtitle">
                  {{$localize.get('preferred_skills')}}
                </div>
                <div class="job__item__content">
                  <div class="tags">
                    <div 
                      v-for="(tagItem, index) in selectedJob[selectedLanguage.locale].tags"
                      :key="'item-' + index"
                    >
                      <div 
                        class="tags__item"
                      >
                        {{ tagItem }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="job__item">
                <div class="job__item__subtitle">
                  {{$localize.get('minimum_requirements_and_work_experience')}}
                </div>
                <div class="job__item__details">
                  <div 
                    class="job__item__details__item--object-list"
                    v-for="(minimumRequirementsItem, index) in selectedJob[selectedLanguage.locale].minimumRequirements"
                    :key="'item-' + index"
                  >
                    <b class="mr-3">{{minimumRequirementsItem.title}}</b> 
                    {{minimumRequirementsItem.value}}
                  </div>
                </div>
              </div>
              <div class="job__item">
                <div class="job__item__subtitle">
                  {{$localize.get('role_responsibilities')}}
                </div>
                <div 
                  class="job__item__content"
                  v-html="selectedJob[selectedLanguage.locale].roleResponsibilities"
                ></div>
              </div>
            </div>
          </div>
          <h-button 
            class="apply-button--bottom"
            green filled
            :large="$vuetify.breakpoint.smAndDown"
            :block="$vuetify.breakpoint.smAndDown"
          >{{$localize.get('apply_for_this_job')}}
          </h-button>
        </div>
      </template>
     </content-section>
  </div>
</template>

<script>
export default {
  name: 'Jobs',
  data () {
    return {
      filterSearchQuery: '',
      selectedJob: null,
      smFilterPanel: false,
      filterDetails: {
        value: false,
        item: null
      },
      filterItems: [
        {
          title: 'sectors',
          selected: [],
          listItems: [
            'test 1',
            'test 2',
          ],
          multiple: true 
        },
        {
          title: 'countries',
          selected: [],
          listItems: [
            'test 1',
            'test 2',
          ],
          multiple: true
        },
        {
          title: 'duration',
          selected: [],
          listItems: [
            'test 1',
            'test 2',
          ],
          multiple: true
        },
        {
          title: 'payment_method',
          selected: '',
          listItems: [
            'test 1',
            'test 2',
          ]
        },
        {
          title: 'location',
          selected: '',
          listItems: [
            'test 1',
            'test 2',
          ]
        },
        {
          title: 'status',
          selected: [],
          listItems: [
            'test 1',
            'test 2',
          ],
          multiple: true 
        },
        {
          title: 'available_for',
          selected: [],
          listItems: [
            'test 1',
            'test 2',
          ],
          multiple: true
        },
        {
          title: '',
          selected: '',
          listItems: [],
          type: 'separator'
        },
        {
          title: 'category',
          selected: '',
          listItems: [
            'test 1',
            'test 2',
          ]
        },
        {
          title: 'work_area',
          selected: '',
          listItems: [
            'test 1',
            'test 2',
          ]
        },
        {
          title: 'role',
          selected: '',
          listItems: [
            'test 1',
            'test 2',
          ]
        },
        {
          title: 'level',
          selected: '',
          listItems: [
            'test 1',
            'test 2',
          ]
        },
        {
          title: 'hard_skills',
          selected: [],
          listItems: [
            'test 1',
            'test 2',
          ],
          multiple: true 
        },
      ],
      page: 1,
      itemsPerPageArray: [6, 12, 24, 100],
      itemsPerPage: 6,
    }
  },
  computed: {
    jobsItems () {
      let items = []
      let testJob = {
        en: {
          title: 'Mobile developer',
          position: 'Back-end developer',
          tags: ['MySQL', 'JavaScript', 'Python', 'Flutter', 'C++', 'Ruby', 'JavaScript', 'Python', 'Flutter', 'C++', 'Ruby'],
          description: 'We are an equal opportunity employer and oppose discrimination and harassment',
          projectDescription: 'Project for developing server-side logic and maintaining a central database. \
            <br><br>You will be required to ensure high performance and responsiveness to requests from the front-end elements. \
          ',
          projectDescriptionDetails: [
            {
              title: 'Sector of project:',
              value: 'e-commerce'
            },
            {
              title: 'Country of project:', 
              value: 'Russia'
            },
            {
              title: 'Duration of project:',
              value: '3-6 months'
            },
            {
              title: 'Start date of project:', 
              value: 'ASAP'
            },
          ],
          roleDescription: 'We are looking for a Python (Django) developer who will be responsible for developing server-side logic and maintaining a central database. \
            <br><br>You will be required to ensure high performance and responsiveness to requests from the front-end elements. \
            <br><br>You need to have a basic understanding of front-end technologies so that you can integrate the user-facing elements developed front-end developers with server-side functionality \
          ',
          roleDescriptionDetails: [
            {
              title: 'Interview stages:',
              value: '2'
            },
            {
              title: 'Test task:', 
              value: 'Yes'
            },
          ],
          minimumRequirements: [
            {
              title: 'Citizenships:',
              value: 'RU, BY, UA, KZ, KY'
            },
            {
              title: 'Location:', 
              value: 'any'
            },
            {
              title: 'Worktime:',
              value: 'Full-time / remote',
            },
            {
              title: 'English level:',
              value: 'Basic'
            },
            {
              title: 'Min years of experiance:',
              value: '12'
            },
            {
              title: 'Level (grade):',
              value: 'Senior'
            }
          ],
          roleResponsibilities: 'Integrate user-facing elements into applications \
            <br><br>Implement data security and protection within the application \
            <br><br>Integrate data storage solutions \
            <br><br>Design and implement reliable, low-latency, and highly performant applications \
          '
        },
        ru: {
          title: 'Разработчик',
          position: 'Бекенд разработчик',
          tags: ['MySQL', 'JavaScript', 'Python', 'Flutter', 'C++', 'Ruby', 'JavaScript', 'Python', 'Flutter', 'C++', 'Ruby'],
          description: 'We are an equal opportunity employer and oppose discrimination and harassment',
          projectDescription: 'Проект для разработки серверной логики и поддержания центральной базы данных. \
            <br><br>Потребуется обеспечить высокую производительность и скорость реагирования на запросы с фронтенда \
          ',
          projectDescriptionDetails: [
            {
              title: 'Сектор проекта:',
              value: 'e-commerce'
            },
            {
              title: 'Страна проекта:', 
              value: 'Россия'
            },
            {
              title: 'Длительность проекта:',
              value: '3-6 мес'
            },
            {
              title: 'Дата начала проекта:', 
              value: 'Как можно скроее'
            },
          ],
          roleDescription: 'Мы ищем разработчика на Python (Django), который будет отвечать за разработку серверной логики и обслуживание центральной базы данных. \
            <br><br>Потребуется обеспечить высокую производительность и скорость реагирования на запросы с фронтенда. \
            <br><br>Нужно иметь базовые знания о передовых технологиях фронтенда \
          ',
          roleDescriptionDetails: [
            {
              title: 'Количество стадий интерьвю:',
              value: '2'
            },
            {
              title: 'Тестовое задание:', 
              value: 'Да'
            },
          ],
          minimumRequirements: [
            {
              title: 'Гражданства:',
              value: 'RU, BY, UA, KZ, KY',
            },
            {
              title: 'Местоположение:', 
              value: 'любое',
            },
            {
              title: 'График:',
              value: 'Полный рабочий день / удаленно',
            },
            {
              title: 'Уровень английского:',
              value: 'Базовый',
            },
            {
              title: 'Минимальный опыт (лет):',
              value: '12'
            },
            {
              title: 'Уровень (grade):',
              value: 'Senior'
            }
          ],
          roleResponsibilities: 'Интеграция пользовательских элементов в приложения \
            <br><br>Внедрение безопасности и защиты данных внутри приложения \
            <br><br>Интеграция решений по хранению данных \
            <br><br>Разработка и внедрение надежных, низколатентных и высокопроизводительных приложений \
          '
        }
      }
      for (let index = 0; index < 50; index++) {
        const randomInt = Math.floor(Math.random() * Math.floor(3))
        const randomInt0to1 = Math.floor(Math.random() * Math.floor(1))
        const testJobClone = JSON.parse(JSON.stringify(testJob))
        testJobClone[this.selectedLanguage.locale].tags.splice(randomInt0to1, randomInt)
        items.push(testJobClone)
      }
      return items
    },
    selectedLanguage () {
      return this.$store.state.storageData.settings.localization.selectedLanguage
    },
    jobsItemsFiltered () {
      return this.jobsItems
    },
    numberOfPages () {
      return Math.ceil(this.jobsItems.length / this.itemsPerPage)
    },
    amountSelectedFilters () {
      return this.filterItems.filter(item => item.selected.length > 0).length
    },
  },
  methods: {
    isMactchingFilterQuery (item) {
      const isMatching = item.toLowerCase().includes(this.filterSearchQuery.toLowerCase())
      return  this.filterSearchQuery === '' || isMatching
    },
    openVacancy (item) {
      this.selectedJob = item
      document.querySelector('.content__section--view__inner__title').scrollIntoView()
    },
    closeVacancy () {
      this.selectedJob = null
      window.scrollTo({top: 0})
    },
    hiddenTagAmount (item) {
      const hiddenAmount = item[this.selectedLanguage.locale].tags.length - 2
      if (hiddenAmount > 0) {
        return this.$vuetify.breakpoint.smAndUp
          ? `+${hiddenAmount} ${this.$localize.get('other', {capitalize: false})}`
          : `+${hiddenAmount}`
      }
      else {
        return ''
      }
    },
    selectedFiltersFormatted (item) {
      if (Array.isArray(item.selected)) {
        if (item.selected.length > 0) {
          return `(${item.selected.join(', ')})`
        }
        else {
          return ''
        }
      }
      else {
        if (item.selected.length > 0) {
          return `(${item.selected})`
        }
        else {
          return ''
        }
      }
    },
    resetFilters () {
      this.filterItems.forEach(item => {
        if (typeof item.selected === 'string') {
          item.selected = ''
        }
        else if (Array.isArray(item.selected)) {
          item.selected = []
        }
      })
    },
    openFilterDetails (item) {
      this.$store.state.drawer.value = true
      this.filterDetails.value = true
      this.filterDetails.item = item
      window.scrollTo({top: 0})
      // document.querySelector('html').style.overflow = 'hidden'
    },
    closeFlterDetails (item) {
      this.$store.state.drawer.value = false
      this.filterDetails.value = false
      this.filterDetails.item = null
      // document.querySelector('html').style.overflow = 'auto'
    }
  },
}
</script>

<style lang="scss">
.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    margin-bottom: 20px;
    margin-right: 8px;
    padding: 0px 16px;
    color: #fff;
    background-color: var(--blue-main);
    border-radius: 100px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    &:nth-child(n + 3) {
      @media (max-width: $md) {
        display: none;
      }
    }
  }
}
#jobs-view {
  font-family: 'Proxima Nova';
  pre {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
  .title-container {
    &__title {
      color: var(--blue-main);
      font-family: 'Proxima Nova Bl';
      font-style: normal;
      font-weight: 900;
      font-size: 28px;
    }
    &__filter {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (min-width: $sm) {
        display: none;
      }
    }
    &--sm {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 32px;
      color: var(--blue-main);
      &[smFilterPanel] {
        margin-bottom: 12px;
      }
      img {
        height: 14px;
        margin-right: 8px;
      }
    }
  }
  .content {
    display: flex;
    align-items: flex-start;
  }
  .filter {
    background-color: #fff;
    padding: 28px;
    min-width: 314px;
    width: 314px;
    border-radius: 8px;
    margin-right: -6px;
    transform: translateX(-32px);
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.02);
    @media (max-width: $sm) {
      display: none;
    }
    .v-input__control > .v-input__slot {
      align-items: stretch;
      min-height: 44px;
      height: 44px;
    }
    .v-text-field--enclosed .v-input__append-inner {
      margin-top: 19px;
    }
    .v-input .v-label {
      line-height: 10px;
    }
    .v-input .v-label.v-label--active {
      line-height: 16px;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 28px;
      font-size: 16px;
      img {
        width: 24px;
        height: 14px;
        margin-right: 12px;
      }
    }
    &__item {
      margin-bottom: 15px !important;
      label {
        color: var(--grey-2);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
      }
      .v-select__selection {
        flex-wrap: nowrap;
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 90%;
      }
      .v-select__selections {
        flex-wrap: nowrap;
        max-width: 90%;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .filter--sm {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: #2E2E2E;
      .h-button {
        font-family: 'Proxima Nova Rg';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
      }
    }
    &__item {
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 44px;
      border-bottom: 1px solid #DBDBDB;
      cursor: pointer;
      &__content {
        display: flex;
        align-items: center;
      }
      &__title {
        margin-right: 8px;
        font-family: 'Proxima Nova Lt';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
      }
      img {
        transform: rotateZ(-90deg);
      }
      &__selected {
        color: var(--grey-2);
        font-family: 'Proxima Nova Lt';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  .filter-details {
    .v-select .v-input__control > .v-input__slot {
      align-items: stretch;
      min-height: 44px;
      height: 44px;
    }
    .v-select .v-text-field--enclosed .v-input__append-inner {
      margin-top: 19px;
    }
    .v-select .v-input .v-label {
      line-height: 10px;
    }
    .v-select .v-input .v-label.v-label--active {
      line-height: 16px;
    }
    &__header {
      .h-button {
        margin-left: -8px;
        .v-icon {
          margin-top: -2px;
          font-size: 32px;
        }
      }
    }
    &__info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: #2E2E2E;
      .h-button {
        font-family: 'Proxima Nova Rg';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        margin-left: -8px;
        .v-icon {
          font-size: 32px;
        }
      }
    }
    &__search {
      margin: 16px 0;
      position: relative;
      input {
        width: 100%;
        height: 32px;
        background: #DBDBDB;
        border-radius: 6px;
        padding-left: 38px;
        font-size: 14px;
        &::placeholder {
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
        }
      }
      .v-icon {
        position: absolute;
        top: 8px;
        left: 12px;
        font-size: 18px;
      }
    }
    &__items {
      margin-top: 26px;
    }
    &__item {
      .v-label {
        color: var(--grey-2);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        top: 12px;
      }
      .v-label.v-label--active {
        top: 18px;
      }
    }
  }
  .main {
    width: 100%;
  }
  .cards {
    &__item {
      display: flex;
      flex-direction: column;
      height: 266px;
      padding: 24px;
      padding-bottom: 4px;
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.2s;
      @media (max-width: $xs) {
        height: 254px;
      }
      &:hover {
        box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
        transform: scale(1.02);
        transition: all 0.2s;
      }
      .h-button {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
      }
      &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
      }
      &__position {
        margin-top: 6px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
      }
      &__description {
        margin-top: 24px;
        margin-bottom: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
      }
      &__tags {
        display: flex;
        align-items: center;
        margin-top: 16px;
        &__item {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          margin-right: 8px;
          padding: 0px 16px;
          color: #fff;
          background-color: var(--blue-main);
          border-radius: 100px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          &:nth-child(n + 3) {
            @media (max-width: $md) {
              display: none;
            }
          }
        }
        &__hidden-item-text {
          @media (max-width: $xs) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    &__results {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      color:  #939CB4;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      @media (max-width: $md) {
        display: none;
      }
      &__selector {
        margin: 0 12px;
        padding: 8px 16px;
        background-color: #fff;
        img {
          margin-left: 32px;
        }
      }
    }
  }
  .subsection {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: $xs) {
        flex-direction: column;
        align-items: flex-start;
      }
      &__path {
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        @media (max-width: $sm) {
          margin-bottom: 32px;
        }
        &__item {
          cursor: pointer;
          &--active {
            cursor: default;
            color: var(--blue-main);
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
          }
        }
        .v-icon {
          margin: 0 24px;
          font-size: 28px;
          @media (max-width: $xs) {
            margin: 0 12px;
          }
        }
      }
      .apply-button--top {
        @media (max-width: $sm) {
          display: none;
        }
      }
    }
    &__content {
      &__title {
        margin-top: 48px;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        @media (max-width: $sm) {
          margin-top: 0;
        }
      }
      &__info {
        display: grid;
        grid-template-columns: 50% 50%;
        @media (max-width: $sm) {
          grid-template-columns: 1fr;
        }
        .job {
          &__item {
            margin-right: 64px;
            &__info {
              display: flex;
              align-items: center;
              &__icon {
                margin-right: 16px;
                font-size: 20px;
                color: var(--blue-main);
              }
            }
            &__subtitle {
              font-family: 'Proxima Nova Rg';
              margin-top: 48px;
              margin-bottom: 20px;
              color: var(--blue-main);
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
            }
            &__content {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              margin-bottom: 24px;
            }
            &__details {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              &__item {
                &--object-list {
                  margin-bottom: 16px;
                }
              }
            }
          }
        }
      }
      .apply-button--bottom {
        margin-top: 32px;
        @media (min-width: $xs) {
          display: none;
        }
      }
    }
  }
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
    border: 1px solid #DCDCDC;
  }
}
</style>